<template>
  <div>
    <button @click="getBounds()">Get Bounds</button>
    <l-map
      ref="map"
      id="map"
      style="height: 350px"
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-geo-json :geojson="geojson"></l-geo-json>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import axios from "axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 20,
      center: [-27.470125, 153.021072],
      crs: "EPSG:4326",
      geojson: null,
      mabObj: null,
      group: new L.FeatureGroup(),
    };
  },
  mounted() {
    this.mapObj = this.$refs.map.mapObject;

    console.log("Before Layer Change Map Object", this.mapObj);

    const baseLayerChange = this.mapObj.on("baselayerchange", (e) => {
      // this.group.clearLayers();
      console.log("After Layer Change Map Object", this.mapObj);
      if (e.name == "zone") {
        let map = document.getElementById("map");

        //Get Map Bound bbox
        let bbox = e.target.getBounds().toBBoxString();
        console.log("bbox", bbox);
        let fetchZoneData = axios
          .get(`http://127.0.0.1:8011/map/fetchZoneData/${bbox}`)
          .then((res) => {
            let features = res.data.response.features;

            let zoneCords = [];
            features.forEach((element) => {
              zoneCords.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: element.geometry.coordinates,
                },
              });
            });

            let zoneGeoJson = {
              type: "Feature",
              features: features,
            };

            let zoneJsonLayer = L.geoJson(zoneGeoJson, {
              style: {
                color: "red",
                weight: 10,
                fillColor: "red",
                lineJoin: "round",
                dashArray: "20,15",
                // opacity: 0.7,
              },
              // zoom: 8,
              // onEachFeature: this.onEachFeature,
            }).addTo(this.mapObj);

            // L.layerGroup(zoneJsonLayer).addTo(this.mapObj);
            // zoneJsonLayer.addTo(this.mapObj);
            // console.log("Zone Layer", zoneJsonLayer);
            // console.log("Map Obj", this.mapObj);
            return;
          });

        return;

        console.log(fetchZoneData);
      }
    });
  },
  methods: {
    async fetchZoneData() {
      debugger;
    },
    getBounds() {
      let map = this.$refs.map.mapObject;
      let bbox = map.getBounds().toBBoxString();
      console.log("bbox", bbox);
    },
  },
  async created() {
    let api = await this.$axios.get(`${this.$API_BASE_MAP}/fetchMap`);
    let result = api.data.response;
    let coords = result.geom.coordinates[0];
    let map = this.$refs.map.mapObject;
    let bbox = map.getBounds().toBBoxString();

    let satellite = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    );
    let streets = (streets = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    ));
    let zone = L.tileLayer(
      "https://services2.arcgis.com/dEKgZETqwmDAh1rP/ArcGIS/rest/services/Active_frontages_in_residential_zones_overlay/FeatureServer/0/",
      { maxZoom: 20 }
    );

    let basemaps = {
      satellite: satellite,
      streets: streets,
      zone: zone,
    };

    L.control.layers(basemaps, null).addTo(map);

    let geoJson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: coords,
          },
          properties: null,
        },
      ],
    };

    let jsonLayer = L.geoJson(geoJson, {
      style: {
        color: "#999",
        weight: 2,
        fillColor: "#00ad79",
        fillOpacity: 0.7,
      },
      zoom: 20,
      // onEachFeature: this.onEachFeature,
    });

    this.group.addLayer(jsonLayer).addTo(map);
    // L.layerGroup(jsonLayer).addTo(map);
    this.geojson = geoJson;
    this.mapObj.fitBounds(jsonLayer.getBounds());
  },
};
</script>
